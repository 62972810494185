import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DataTable.css';

export interface DataItem {
    name: string;
    line: number;
    odds: number;
    site: string;
    match: string;
    team: string;
    probFromPitch: number;
    prob4: number;
    value4: number;
    L10prob: number;
    L5prob: number;
    pitcher: any[];
    L10HitRate: number;
    startTime: string;
    [key: string]: any;
}

function convertUtcToLocalTime(timeStr: string): string {
    const [hour, minute] = timeStr.split(':').map(Number);
    const now = new Date();
    const utcDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute));
    const localTimeStr = utcDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Use user's local timezone
    return localTimeStr;
}

export interface TableProps {
    data: DataItem[];
}

const DataTable: React.FC<TableProps> = ({ data }) => {
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: string }>({ key: 'value4', direction: 'desc' });

    const handleSort = (key: string) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const [isExtended, setIsExtended] = useState(window.innerWidth >= 400);

    const handleCheckboxChange = () => {
        setIsExtended(prev => !prev);
    };

    const sortedData = React.useMemo(() => {
        if (sortConfig) {
            const { key, direction } = sortConfig;
            return [...data].sort((a, b) => {
                if (a[key] < b[key]) {
                    return direction === 'asc' ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return data;
    }, [data, sortConfig]);

    return (
        <div className="container mt-5 table-container">
            <div className="table-responsive-horizontal">
                <label style={{
                    color: '#242936',
                    fontSize: '12px', // Decrease font size
                    display: 'flex',  // Use flexbox to align items
                    alignItems: 'center', // Vertically center items
                    justifyContent: 'flex-start', // Align items to the left
                    margin: '0', // Remove margin if needed
                    padding: '0', // Remove padding if needed
                }}>
                    Show Extended Version (you may need to scroll)
                    <input type="checkbox" checked={isExtended} onChange={handleCheckboxChange} style={{ marginLeft: '5px' }} />
                </label>
                <table className="table table-striped table-responsive">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('name')}>Name</th>
                            <th className={isExtended ? '' : 'hidden-column'} onClick={() => handleSort('line')}>Line</th>
                            <th onClick={() => handleSort('odds')}>Odds</th>
                            <th onClick={() => handleSort('site')}>Site</th>
                            <th className={isExtended ? '' : 'hidden-column'} onClick={() => handleSort('match')}>Match</th>
                            <th onClick={() => handleSort('team')}>Team</th>
                            <th>Time</th>
                            <th className={isExtended ? '' : 'hidden-column'} onClick={() => handleSort('probFromPitch')}>Projected Hits</th>
                            <th className={isExtended ? '' : 'hidden-column'} onClick={() => handleSort('L10prob')}>L10</th>
                            <th className={isExtended ? '' : 'hidden-column'} onClick={() => handleSort('L5prob')}>L5</th>
                            <th className={isExtended ? '' : 'hidden-column'}>L10 Hits/Game</th>
                            <th className={isExtended ? '' : 'hidden-column'}>SP OBA</th>
                            <th onClick={() => handleSort('prob4')}>{isExtended ? 'Probability' : 'Prob'}</th>
                            <th onClick={() => handleSort('value4')}> {isExtended ? 'Expected Value' : 'EV'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item) => (
                            <tr key={item.name} className={
                                item.value4 > 0.05 ? 'table-success' :
                                    item.value4 > 0.0 ? 'table-warning' :
                                        item.value4 > -0.05 ? 'table-caution' : 'table-danger'
                            }>
                                <td>{isExtended ? item.name : item.name.split(' ')[0][0] + ". " + item.name.split(' ').slice(1).join(' ')}</td>
                                <td className={isExtended ? '' : 'hidden-column'}>{item.line}</td>
                                <td>{item.odds}</td>
                                <td>{isExtended ? (item.site === 'FD' ? "Fanduel" : item.site === 'DK' ? "DraftKings" : item.site) : item.site}</td>
                                <td className={isExtended ? '' : 'hidden-column'}>{item.match}</td>
                                <td>{item.team}</td>
                                <td>{isExtended ? convertUtcToLocalTime(item.startTime) : convertUtcToLocalTime(item.startTime).slice(0, -2)}</td>
                                <td className={isExtended ? '' : 'hidden-column'}>{item.probFromPitch}</td>
                                <td className={isExtended ? '' : 'hidden-column'}>{(item.L10prob * 10).toFixed(1)}/10</td>
                                <td className={isExtended ? '' : 'hidden-column'}>{(item.L5prob * 5).toFixed(1)}/5</td>
                                <td className={isExtended ? '' : 'hidden-column'}>{(item.L10HitRate.toFixed(2))}</td>
                                <td className={isExtended ? '' : 'hidden-column'}>{(item.pitcher[0].toFixed(3))}</td>
                                <td>{isExtended ? (item.prob4 * 100).toFixed(1) : (item.prob4 * 100).toFixed()}%</td>
                                <td>{(item.value4 > 0 ? '+' : '') + (isExtended ? (item.value4 * 100).toFixed(1) : (item.value4 * 100).toFixed())}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DataTable;