import React, { useEffect, useState } from 'react';
import './App.css';
import DataTable, { DataItem } from './components/DataTable';
import api from './api/api';

const App: React.FC = () => {
  const [tableData, setTableData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // State for FanDuel and DraftKings selection
  const [fanduelSelected, setFanduelSelected] = useState<boolean>(true);
  const [dkSelected, setDkSelected] = useState<boolean>(true);

  // State for showing/hiding the dropdown
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  // Function to fetch data based on selection
  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      let apiUrl = '';

      if (fanduelSelected && dkSelected) {
        apiUrl = '/tableValues'; // Both selected
      } else if (fanduelSelected) {
        apiUrl = '/fanduelOffers'; // Only FanDuel selected
      } else if (dkSelected) {
        apiUrl = '/dkOffers'; // Only DraftKings selected
      }

      if (apiUrl) {
        const response = await api.get<DataItem[]>(apiUrl);
        setTableData(response.data);
      } else {
        setTableData([]); // Clear data if nothing is selected
      }

      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data');
      setLoading(false);
    }
  };

  // Effect to fetch data when selection changes
  useEffect(() => {
    fetchData();
  }, [fanduelSelected, dkSelected]);

  // Handling checkbox changes
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === 'fanduel') {
      setFanduelSelected(checked);
    } else if (name === 'draftkings') {
      setDkSelected(checked);
    }
  };

  // Toggle the visibility of the dropdown menu
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  if (loading) {
    return (
      <div className="loading-container" style={{ backgroundColor: '#242936' }}>
        <div className="loading-spinner"></div>
        <div>Loading...</div>
        <div>If this process takes more than 10 seconds, the database is being rebooted.</div>
        <div>Please wait approximately 1 minute.</div>
        <div>If the website still does not load, please contact me @PropHitter on X (formerly Twitter)</div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ marginBottom: '20px' }}></div>
        <h1 style={{ marginBottom: '0px', marginTop: '20px' }}>Hit EVs</h1>

        {/* Dropdown for sports books */}
        <div className="dropdown-container" style={{ marginBottom: '20px', textAlign: 'left' }}>
          <button onClick={toggleDropdown} className="dropdown-button">
            Sportsbooks <span className="arrow">▼</span>
          </button>

          {dropdownVisible && (
            <div className="dropdown-content" style={{ padding: '10px', backgroundColor: '#333', borderRadius: '5px' }}>
              <label style={{ display: 'block', marginBottom: '10px' }}>
                <input
                  type="checkbox"
                  name="fanduel"
                  checked={fanduelSelected}
                  onChange={handleCheckboxChange}
                />
                FanDuel
              </label>

              <label style={{ display: 'block' }}>
                <input
                  type="checkbox"
                  name="draftkings"
                  checked={dkSelected}
                  onChange={handleCheckboxChange}
                />
                DraftKings
              </label>
            </div>
          )}
        </div>

        {/* Table displaying data */}
        <DataTable data={tableData} />
      </header>
    </div>
  );
};

export default App;
